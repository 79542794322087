
$background_color:#070302;



























// ***********************************************************************************************
// ***********************************************************************************************
// ***********************************************************************************************
// ***********************************************************************************************
// ***********************************************************************************************
// this coolor not use any where pls dont us this color 
$pinkcolor:#DD127B;
$naviblue:#0F0C80;
$lightpink:#DD127B;
$lightbluenavy:#901D78;
$textcolorsmall:#494949;
$continue:#1F41BB;
$black:#000;
$white:#fff;
// this coolor not use any where pls dont us this color 

// ***********************************************************************************************
// ***********************************************************************************************
// ***********************************************************************************************
// ***********************************************************************************************
// ***********************************************************************************************




