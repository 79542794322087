@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@import '../css/Veriable.scss';
*{
    padding:0px;
    margin:0px;
    box-sizing: border-box;
}
body{
    background-color: #fff;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --d: 700ms;
    --e: cubic-bezier(0.19, 1, 0.22, 1);
}
a{
    text-decoration: none;
}

.section-top-bottom{
    margin:60px 0px;
}
.section-padding-top-bottom{
    padding:60px 0px;
}
.readmore{
    background:$pinkcolor;
    color:#fff;
}
.readmore:hover{
    border:1px solid $pinkcolor;
    color:$pinkcolor;
}
.sub-page-banner {
    background:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../../src/assets/image/sub-banner.jpg');
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0px;
    background-size: cover;
    background-position: bottom;
    .sub-banner {
        text-align: center;
        h1 {
            font-family: "Exo", sans-serif;
            color: #fff !important;
            text-transform: uppercase;
            font-weight: 800;
        }
        ul{
            li{
                color:#fff
            }
        }
    }
}
.margin-top-bottom{
    margin:60px 0px
}