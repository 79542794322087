@import url("../src/css/Veriable.scss");
$background_color: #fbc503;
$background_2: #396;
* {
  font-family: inherit;
}

body {
  background-color: $background_color;
  color: white;
}
.quicklink a,
.footlink a {
  background-color: $background_2 !important;
}
#footer_right {
  text-align: center;
  color: #ffffff;
  background-color: #ff030b;
  border-top-left-radius: 11px;
  border-bottom-right-radius: 11px;
  border-top-right-radius: 00px;
  border-bottom-left-radius: 00px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 2%;

  position: fixed;
  bottom: 14px;
  right: 0;
  justify-content: space-around;
  z-index: 10;
  overflow: hidden;
}
.quicklink a {
  margin: 5px;
}

.width_full_one {
  .row {
    .gamefull:nth-of-type(7n + 1) {
      background: #bf5010;
      width: 100%;
      min-height: auto;
    }
  }
}
.border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) a,
.border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) .gamefull-result {
  color: #fff !important;
}
.border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) .yellow_color {
  color: yellow !important;
}
.gamefull {
  padding: 0 1px 0 1px;
  border: 1px solid black;
  background-color: #f5ecce;
  text-align: center;
  min-height: 110px;
}

.border.row {
  background: #f5ecce;
}
.livegame {
  color: #ff0088;
  font-size: 34px;
  font-weight: bold;
}
.liveresult {
  color: green;
  font-size: 34px;
  font-weight: bold;
}
.pages {
  height: 55px;
  font-weight: 600;
}
.king {
  width: 100%;
  background: $background_2;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 18px;
}
.king h1 {
  font-size: 40px;
  font-weight: bold;
  color: #000;
  padding: 6px;
}

.play_now {
  width: 120px !important;
}

.float {
  // background-color: #25d366;
  border-radius: 10px;
  bottom: 60px;
  // box-shadow: 2px 2px 3px #999;
  // color: #fff;
  font-size: 30px;
  position: fixed;
  left: 15px;
  text-align: center;
  width: 48px;
  z-index: 100;
  // padding: 5px;
}

.float.telegram {
  right: 15px;
  left: inherit;
  background: none;
  padding: 0;
  box-shadow: none;
}

.floatt {
  bottom: 60px;
  font-size: 30px;
  height: 60px;
  position: fixed;
  left: 40px;
  text-align: center;
  width: 130px;
  z-index: 100;
}

a {
  color: white;
  text-decoration: none;
}

h2 {
  color: #fff;
  font-size: 15px;
  text-align: center;
}
p {
  margin: 0 0 0px;
}
.king h1 {
  text-align: center;
  margin-top: 10px;
  color: #000;
}
.king-heading h2 {
  text-align: center;
  margin-top: 10px;
  background-color: $background_2;
  border: 2px solid #fff;
  padding: 7px;
  color: #fff;
  font-size: 26px !important;
}
.style1 {
  background: #fff;
  text-align: center;
  color: white;
  width: 100%;
  padding-top: 7px;
}
// .style1  p{color: white;}
.style1-heading {
  font-weight: bold;
  color: #000;
  font-size: 20px;
}
.select-date {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.ad-banner {
  background-color: #fff;
  color: white;
  font-weight: bold;
  font-size: large;
  border-width: 3px;
  border-color: #d69941;
  border-style: outset;
  margin: 2px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}
.ad-banner button {
  background-color: #d69941;
}
.ad-whatapp button {
  background-color: green;
}
.ad-banner-#d69941 {
  color: #d69941;
}
.ad-banner-green {
  color: green;
}
.ad-banner-blue {
  color: blue;
}
.ad-banner-purple {
  color: #580aa2;
}
.ad-banner-black {
  color: #000;
}

.gameinchart {
  padding: 0 1px 5px 0px;
  border: 1px solid black;
  background-color: #f3092a;
  text-align: center;
}
.gameinchart a {
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
}
.gameinchart-result {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
.tr1 {
  background-color: #ffffff;
  color: #000000;
}
.drag {
  background-color: $background_2;
  text-align: center;
  text-shadow: 1px 1px blue;
  font-size: 20px;
  border: 2px solid lime;
}
.drag h2 {
  margin-top: 4px;
  margin-bottom: 4px;
  color: #f5eeee;
}
.drag a {
  color: #fff;
  font-size: 25px;
}
.list {
  background-color: #ffffff;
  color: #ffffff;
  border-style: double;
  border-color: #990033;
}

.list a {
  text-decoration: none;
  color: #11499d;
  font-size: 18px;
}

.footer {
  background-color: #d69941;
  border: 4px solid #000;
  text-align: center;
}
.hotlink-black {
  border: 1;
  text-align: center;
  width: 100%;
}
.hotlink-black td {
  padding: 2px;
  border: 2px solid $background_color;
  text-align: center;
  width: 50%;
  background-color: blue;
}
.hotlink {
  border: 1;
  text-align: center;
  width: 100%;
}
.hotlink td {
  padding: 2px;
  border: 2px solid $background_color;
  text-align: center;
  width: 50%;
  background-color: #000;
  font-weight: bold;
}
.hotlink a {
  text-decoration: none;
  font-weight: bold;
  color: #fff;
}
.pages {
  width: 100%;
  text-align: center;
}
.pages a {
  text-decoration: none;
  text-align: center;
  color: #fff;
}
.pages td {
  padding: 8px 5px 8px 5px;
  text-align: center;
  background-color: #4d4dff;
  border: 2px solid #000;
}

.footer-pages {
  width: 100%;
  text-align: center;
}
.footer-pages td {
  padding: 8px 5px 8px 5px;
  text-align: center;
  background-color: $background_2;
  border: 2px solid #fff;
}
.bus {
  color: #fff;
  background-color: $background_color;
  font-weight: bold;
}
.bookmark {
  background-color: $background_2;
  color: white;
  font-weight: bold;
  font-style: bold;
  font-size: large;
  text-decoration: none;
  border-width: 3px;
  border-color: $background_color;
  border-style: outset;
  margin: 2px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}
.bookmark font {
  size: 3;
  color: #000;
}

.chart {
  width: 100%;
  overflow-x: scroll;
  margin: auto;
  color: #cccccc;
}

.rtable {
  border: 1px thin;
  text-align: center;
  width: 100%;
}

.nrtable {
  width: 100%;
}
.nrtable th {
  border: 1px solid $background_color;
  background-color: #d69941;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 5px 0px 5px;
}
.nrtable td {
  border: 1px solid $background_color;
  color: #000;
  font-size: 15px;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
  text-align: center;
  background-color: #fff;
}
.nrtable-fon {
  background-color: #212529 !important;
  color: #fff !important;
}
.nrtable-date {
  background-color: #cec4eb !important;
}
.rtable td {
  border: 1px solid #000;
  color: #000;
}
.rtable th {
  border-color: #000;
  background-color: #cc4c1a;
  color: #cccccc;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 5px 0px 5px;
}

.list {
  background-color: #ffffff;
  color: #ffffff;
  border-style: double;
  border-color: $background_2;
  text-align: center;
  padding: 5px;
  border-radius: 19px;
}
.list a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}
.list2 {
  background-color: $background_color;
  color: #ffffff;
  border-style: double;
  border-color: #990033;
  text-align: center;
}
.list2 a {
  text-decoration: none;
  color: #ff0000;
  font-size: 16px;
}
.list3 {
  background-color: #ffff99;
  color: #ffffff;
  border-style: double;
  border-color: #990033;
  text-align: center;
}
.list3 a {
  text-decoration: none;
  color: #ff0000;
  font-size: 16px;
}

.list4 {
  background-color: $background_color;
  border-radius: 8px;
  border-style: solid;
  border-color: #d69941;
  text-align: center;
  padding: 5px 0 5px 0;
}
.list4 a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}
.content {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-style: bold;
  font-size: large;
  text-decoration: none;
  border-width: 3px;
  border-color: $background_2;
  border-style: outset;
  margin: 3px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}
.content h3 {
  color: #d69941;
  font-size: 21px;
  font-weight: bold;
}
.content a {
  color: Red;
}
.content p {
  font-size: 15px;
  text-align: justify;
}
.livegame {
  color: $background_2;
  font-size: 34px;
  font-weight: bold;
}
.liveresult {
  color: green;
  font-size: 34px;
  font-weight: bold;
}
.style4 {
  color: brown;
  font-size: 15px;
  font-weight: bold;
}
.style5 {
  color: #d69941;
  font-size: 20px;
  font-weight: bold;
}
.style6 {
  color: blue;
  font-size: 15px;
  font-weight: bold;
}
.style7 {
  color: darkgreen;
  font-size: 15px;
  font-weight: bold;
}
.ttd {
  border-color: #000;
  background-color: #b90621;
  color: #cccccc;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 5px 0px 5px;
}

.quicklink {
  text-align: center;
  font-weight: bold;
  margin: 18px 0 18px 0;
}
.quicklink a {
  text-decoration: none;
  background-color: $background_2;
  border-radius: 10px;
  border-color: #d69941;
  padding: 9px;
  color: #fff;
}
.msg {
  background-color: #fff;
  color: #000;
  border: 2px solid blue;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}
.msg p {
  font-size: 18px;
  font-weight: bold;
}
.msg a {
  font-size: 19px;
  text-decoration: none;
  background-color: #ff0018;
  padding: 3px 7px 3px 7px;
  color: #f1e7e7;
  font-weight: bold;
  border: 1px solid#000;
  border-radius: 10px 10px 10px 10px;
}
.gamefull {
  padding: 0 1px 0 1px;
  // border: 1px solid black;
  // background-color:#F5ECCE;
  text-align: center;
  min-height: 110px;
}
.gamefull p {
  color: #000;
  font-weight: bold;
}
.gamefull a {
  text-transform: uppercase;
  color: #d69941;
  font-size: 18px;
  font-weight: bold;
}
.gamefull-result {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.gamehalf {
  padding: 3px 1px 3px 1px;
  border: 1px solid black;
  min-height: 110px;
  text-align: center;
  background-color: #f5ecce;
}
.gamehalf a {
  color: #d69941;
  font-size: 17px;
  font-weight: bold;
}
.gamehalf p {
  color: #000;
  font-weight: bold;
}
.gamehalf-result {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.hr-border {
  margin-top: 11px;
  margin-bottom: 11px;
  border: 0;
  border-top: 1px solid #f30a0a;
}

.matka-result {
  background-color: #000;
  text-align: center;
  padding: 0px 0px 0px 0px !important;
  border: 3px solid #fff;
}
.matka-result h2 {
  font-size: 20px;
  color: yellow;
  margin-top: 10px;
  margin-bottom: 16px;
}
.matka-result h5 {
  font-size: 25px;
  color: #fff;
  background-color: #f13f68;
  margin: 0px;
  font-weight: bold;
  padding: 7px;
}
.matka-live-game {
  background-color: #fff;

  padding: 8px;
  border-bottom: 2px solid #133469;
}
.matka-live-game h4 {
  font-size: 22px;
  font-weight: bold;
  color: #090909;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.matka-live-game span {
  font-weight: bold;
  color: #9c0000;
  font-size: 21px;
}
.refress {
  background-color: #2c2841 !important;
  border: 1px solid #2c2841 !important;
  padding: 1px 10px !important;
  font-size: 15px !important;
  font-style: italic;
  font-weight: bold !important;
}
.m_section {
  background-color: #fff;
}
.m_layout {
  padding: 3px 1px 3px 1px;
  border: 1px solid black;
  min-height: 110px;
  text-align: center;
  background-color: #fff;
}
.m_game {
  padding: 5px 5px 0px 5px;
}
.m_game_name {
  background-color: #d69941;
  font-size: 26px;
  color: #fff;
}
.m_game_tab {
  width: 100%;
  text-align: center;
}
.m_game_tab td {
  padding: 5px;
}
.m_light {
  background-color: #000;
  color: #fff;
  font-size: 16px;
}
.m_result {
  color: #000;
  font-size: 21px;
  border-top: 1px solid;
}
.m_result span {
  font-weight: bold;
  font-size: 26px;
  color: #0e16b8;
}
.timetable p {
  margin: 0 0 0px;
  font-size: 25px;
  font-weight: bold;
  // background-color: yellow;
  color: #000;
  text-align: center;
  padding: 3px 0 0 0;
}
.timetable table {
  width: 100%;
}
.timetable td {
  border: 2px solid #ff0;
  color: #efefe7;
  padding: 6px;
  font-size: 17px;
  padding-left: 15px;
}
.covid_note {
  background-color: #000;
  text-align: center;
  border: 1px solid #fff;
  padding: 5px;
}
.covid_note a {
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
}

// Download Button Css

#wrap {
  margin: 20px auto;
  text-align: center;
}
.btn-slide2 {
  display: inline-block;
  height: 50px;
  width: 200px;
  line-height: 50px;
  padding: 0;
  border-radius: 50px;
  background: #fdfdfd;
  border: 2px solid #0099cc;
  margin: 10px;
  transition: 0.5s;
}

.btn-slide2 {
  border: 2px solid #efa666;
  svg {
    font-size: 20px;
  }
}

.btn-slide2:hover {
  background-color: #efa666;
}

.btn-slide:hover span.circle,
.btn-slide2:hover span.circle2 {
  left: 100%;
  margin-left: -45px;
  background-color: #fdfdfd;
  color: #0099cc;
}

.btn-slide2:hover span.circle2 {
  color: #efa666;
}

.btn-slide:hover span.title,
.btn-slide2:hover span.title2 {
  left: 40px;
  opacity: 0;
}

.btn-slide:hover span.title-hover,
.btn-slide2:hover span.title-hover2 {
  opacity: 1;
  left: 40px;
}

.btn-slide span.circle,
.btn-slide2 span.circle2 {
  display: block;
  color: #fff;
  position: absolute;
  float: left;
  margin: 3px 5px;
  line-height: 42px;
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 50%;
}

.btn-slide2 span.circle2 {
  background-color: #efa666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-slide span.title,
.btn-slide span.title-hover,
.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  position: absolute;
  left: 90px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: #30abd5;
  transition: 0.5s;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  color: #efa666;
  left: 70px;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
  left: 80px;
  opacity: 0;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2 {
  color: #fff;
}

.app_ss {
  background: #d69941;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  h2 {
    font-size: 42px;
    font-family: system-ui;
    padding-top: 50px;
  }
  .slick-slider {
    height: 100vh;
  }
}

.slick-dots {
  position: relative;
  li button:before {
    font-size: 8px;
  }
}

.slick-slide div div:focus-visible {
  outline: none;
}
