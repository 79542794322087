@media screen and (max-width:1024px) {
    .chosse-img,.chosse-img2{
        display:none;
    }
}
@media screen and (max-width:991px) {
    .testimonial-section-five .sec-title-three h2 span {
        position: relative;
        display: inline-block;
    }
    #Empowerstudent{
        .contentempower p {
            font-size: 13px;
            letter-spacing: normal;
            line-height: 1.9;
            padding: 0;
            font-weight: normal;
            text-align: justify;
        }
        .contentempower .headingfortittle {
            font-size: 27px;
        }
    }
    .bg-download .choose-content p {
        padding-right: 0px;
        text-align: justify;
    }
    #Login{
        .section--padding {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        .hero-1-shape {
            width: 56px;
        }
        .headingschool h3 span {
            font-size: 17px;
        
    }
    .theme-btn {

        margin: 0px 0px;
    }
    .btn-text {
        font-size: 11px;
    }
    .form-group {
        margin-bottom: 0.5rem;
    }
    #Aboutus 
    {
        .section-title__title {
            font-size: 30px;
            line-height: 25px;
           
        }
        .section-title__title {
            font-size: 24px;
            line-height: 30px;
            color: var(--eduact-black);
            margin: 0;
        }
        .about-two {
            padding: 25px 0;
        }
        .about-two__content {
            padding-top: 0px;
        }
    }
}
    .contactus{
        .contact-one {
            position: relative;
            padding: 25px 0 25px;
        }
        .section-title__tagline {
            padding: 0 7px;
        }
        .contact-info .container ul li {
            padding: 9px 20px 10px;
            width:100%;
        }
        .contact-info .container ul li.active {
            padding: 15px 20px 13px;
        }
        .contactus .contact-info .container ul li .contact-info__text {
            font-size: 18px;    
        }
        .contact-info .container ul li .contact-info__title {
            margin-bottom:5px;
        }
    }
    #Footer{
        .footer-widget{
            margin-top:15px;
        }
        .f-widget-title h5 {
            color: #fff;
            font-size: 19px;
            margin-bottom: 15px;
            padding-bottom: 11px;
            position: relative;
        }
        .f-contact span {
            width: 100%;
        }
    }
    #carrer{
        p{
            text-align: justify;
        }
        .margin-top-bottom {
            margin: 30px 0px;
        }
        .w-75.mx-auto{
            width:100%!important;
        }
        .utf-job-listing-details{
            flex-wrap:wrap;
        }
        .compact-list-layout .utf-job-listing {
            padding: 5px;
            padding-right: 0px;
        }
        .school_logo{
            width: 146px;
            margin-bottom: 15px;
        }
        .utf-job-listing-description{
            p{
                font-size: 13px;
            }
        }
        .utf-job-listing-footer ul li {
            font-size: 11px;
        }
    }
    .sub-page-banner {
        padding: 35px 0px;
    }
    #Login .hero-2-shape {
        z-index: -1;
    }
    .login {
        .menu-link{
            span{
                display:none
            }
        }
    }
    #Home {
        .HeaderContent {
            h2 {
                font-size: 45px;
            }

            .hero-banner-two__shape3 {
                width: 100px;
            }

            .hero-banner-two__shape2 {
                bottom: -7px;
    width: 40px;
            }
        }
    }

    // project counter 
    #projectcounter {
        .container {
            .row {
                .col-md-6 {
                    margin: 0px 0px;

                    .number_counter:before {
                        right: 44px;
                    }
                }
            }
        }
    }

    // about us home 
    #AboutusHome {
        .section--padding {
            padding-top: 40px;
            padding-bottom: 0px;

            .shap_3 {
                left: 100%;
            }

            .shap_2 {
                bottom: 1%;
            }
        }

        .section-top-bottom {
            margin: 0px 0px;
        }

    }

    // Educationcentury
    #Educationcentury {
        .section-padding-top-bottom {
            padding: 15px 0px;
        }
        .contentempower p {
            font-size: 14px;
            letter-spacing: normal;
            line-height: 1.9;
            padding: 0.5rem 0;
            font-weight: normal;
            text-align: justify;
        }
        .heading_tittle {
            h2 {
                font-size: 48px;
                -webkit-text-stroke: 1px;
                text-align: center;
                margin-top: 16px;
                display: inline-block;
            }
        }

        .margin-top-bottom {
            margin: 90px 0px 0px;
        }

        .card-new .imagepoints {
            height: 230px;
        }

        .list-style li {
            font-size: 13px;
            &:before {
                left: -14px;
                width: 10px;
                height: 10px;
                border: 2px solid #DD127B;
                top: 3px;
            }
        }

        .card-new {
            padding: 17px;
            height: 307px;
            display: flex;
    align-items: center;
        }
    }

    // tree 
    #tree {
        .tree li>div {
            background-color: #fff;
            color: #fc3494;
            padding: 10px;
            display: inline-block;
            font-size: 8px;
            font-weight: 500;
            border-radius: 5px;
        }
    }

    // android ios download 
    .bg-download {

        .chosse-img,
        .chosse-img2 {
            display: none;
        }

        .choose-btn {
            display: flex;

            a.g-btn {
                width: 100%;
            }

            a {
                padding: 10px 10px;
            }
        }
    }

    // blog section 
    #blog {
        .single-post {
            margin: 5px 0px;
        }
    }

    // testimonial 
    .testimonial-section-five {
        .sec-title-three h2 {
            font-size: 30px
        }

        .sec-title-three .title {
            font-size: 20px;
            margin-bottom: 7px;
        }
    }

    // footer 
    #Footer {
        .footer-bg:before {
            background-position: bottom;
        }
        .mt-15 {
            margin-top: 15px;
            background: #fff;
            margin-left: 0px;
            border-radius: 6px;
        }
    }
}

@media (max-width:520px) {
   
    .bg-download {
        .choose-btn {
            flex-direction: column;

            a {
                width: 100%;
            }
        }
    }

    .testimonial-section-five {
        padding: 46px 0px 0px;
    }

    .shap_1,
    .shap_3,
    .shap_2,
    .pattern-layer-four,
    .pattern-layer-two {
        display: none
    }

    #tree {
        .bg-i{
            display: none;
        }
        .tree li>div {
            background-color: #fff;
            color: #fc3494;
            padding: 4px;
            display: inline-block;
            font-size: 11px;
            font-weight: 500;
            border-radius: 5px;
            text-align: center;
        }
    }

    // Educationcentury

    #Educationcentury {
        .heading_tittle {
            h2 {
                font-size: 28px;
                -webkit-text-stroke: 1px;
                text-align: center;
                margin-top: 16px;
                display: inline-block;
                left: 14%;
            }
        }
        .card-new .imagepoints {
            height: 100%;
        }
        .afterhover h3{
            font-size:16px
        }
        .list-style li {
            font-size: 11px;
            margin-left: 25px;
        }
    }
    #Home {
        padding: 5px 0;
        .HeaderContent{
            .hero-banner-two__shape5 {
                width: 27px;
                right: 19px;
            }
            .hero-banner-two__shape3 {
                width: 49px;
                top: 22px;
            }
        }
        .Form-container {
            padding: 10px;
        }
    }
    #projectcounter{
        margin-top: 1px;
        .counter_new{
        h6 {
            font-size: 12px;
            margin: 0px 0 0;
        }
        p {
            font-size: 26px;
            margin-bottom: 0px;
        }
       }
       .container .row .col-md-6 .number_counter:before {
        right: 18px;
    }
    }
    .section-top-bottom {
        margin:15px 0px;
    }
    .pt-100 {
        padding-top: 49px;
    }
    .choose-btn{
        .btn_application{
            margin-bottom: 10px;
        }
    }
    #contact .form-touch {
        background: #f9f7ff;
        padding: 10px;
        border-radius: 10px;
    }
}